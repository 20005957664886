import React, { useEffect, useState } from 'react';
import './../../App.css';
import ICON from './../../image.svg';
import ICON2 from './../../upload.svg';



var AWS = require('aws-sdk');
AWS.config = new AWS.Config({ region: 'eu-west-1' });
AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: 'eu-west-1:5167c627-9fe6-407c-8821-c9136edfb834' });

const rekognition = new AWS.Rekognition();
const s3 = new AWS.S3();
const S3_BUCKET = "react-rekognition-celeb";



const V1 = () => {

    const [crtImage, setCrtImage] = useState();
    const [sizeImage, setSizeImage] = useState({ width: 450, height: 600 });
    const [currentData, setCurrentData] = useState([]);
    const [imageState, setImageState] = useState({ processed: false, icon: false, message: 'Analisar Foto', init: false })
    const [isUploading, setIsUploading] = useState(false);
    const [assetList, setAssetList] = useState([]);

    useEffect(() => {
        loadAssets();
    }, []);

    const uploadImage = () => {
        document.getElementById("upload-input").click();
    }

    const uploadImageOnChange = (data) => {
        //console.log(data.target.files);

        var reader = new FileReader();
        reader.readAsArrayBuffer(data.target.files[0]);

        reader.onload = function () {
            let result = reader.result;
            let array = new Uint8Array(result);
            uploadAsset(array, data.target.files[0].name);
        }
    }

    const setAnalyzeImage = async (image) => {
        setCrtImage(image);
        return await setTimeout(() => {
            var imageSize = document.getElementById('photo');
            setSizeImage({ width: imageSize.clientWidth, height: imageSize.clientHeight });
        }, 600);
    }

    const analyzePicture = async (url, key) => {
        setImageState({ processed: false, icon: false, message: 'Analisar Foto', init: true });
        setCurrentData([]);
        await setAnalyzeImage(url);
        var params = {
            Image: {
                S3Object: {
                    Bucket: S3_BUCKET,
                    Name: key,
                }
            }
        };
        rekognition.recognizeCelebrities(params, function (err, data) {
            if (err) {
                setImageState({ processed: false, icon: true, message: 'Ocorreu um erro!', init: true });
                //console.log(err, err.stack);
            }
            else {
                if (data.CelebrityFaces.length > 0) {
                    setImageState({ processed: true, icon: true, message: 'Match Encontrado', init: true });
                } else {
                    setImageState({ processed: false, icon: true, message: 'Match Não Encontrado', init: true });
                }
                setCurrentData(data.CelebrityFaces);
                //console.log(data.CelebrityFaces);
                //console.log(data.CelebrityFaces);
            }
        });
    }

    const loadAssets = () => {
        var params = {
            Bucket: S3_BUCKET,
        };
        s3.listObjectsV2(params).promise().then(data => {
            setAssetList([]);
            setAssetList(data.Contents);
        }).catch(error => {
            //console.log(error);
        });
    }

    const uploadAsset = (bytes, name) => {
        setIsUploading(true);
        var params = {
            Body: bytes,
            Bucket: S3_BUCKET,
            Key: name
        };
        s3.putObject(params).promise().then(data => {
            loadAssets();
            setIsUploading(false);

        }).catch(error => {
            setIsUploading(false);

        })
    }

    return (
        <div className="main-container">
            <div className="container" style={{ flexDirection: 'column', borderRight: '1px solid rgba(165, 42, 42, 0.4)' }}>
                <div className="container-upload">
                    <div id="upload-btn" className="upload-button" onClick={() => { if (!isUploading) uploadImage() }} disabled>
                        <div className="button-layer" style={{ display: isUploading ? 'flex' : 'none' }}>
                            <div className="spinner">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                            </div>
                        </div>
                        <img src={ICON2} alt="" />
            Upload Foto
          </div>
                    <input id="upload-input" onChange={uploadImageOnChange} accept="image/x-png,image/jpeg" type="file" hidden />
                </div>
                <div className="container-option">
                    <div className="container-row-wrap">
                        {
                            assetList.map((prop, key) => {
                                if (assetList.length > 0)
                                    return (
                                        <CardList
                                            key={key}
                                            bucketKey={prop.Key}
                                            action={(data) => analyzePicture(data.url, data.key)} />)
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="card-placeholder" style={{ display: imageState.init ? 'none' : 'flex' }}>
                    <img src={ICON} alt="" />
                    <p>Escolha uma Foto</p>
                    <p>ou</p>
                    <p>Faça Upload</p>
                </div>
                <div className="card">
                    {
                        currentData.map((prop, key) => {
                            if (currentData.length > 0)
                                return (
                                    <div key={key} className="borderbox" style={{
                                        left: prop.Face.BoundingBox.Left * sizeImage.width + 'px',
                                        top: prop.Face.BoundingBox.Top * sizeImage.height + 'px',
                                        width: prop.Face.BoundingBox.Width * sizeImage.width + 'px',
                                        height: prop.Face.BoundingBox.Height * sizeImage.height + 'px'
                                    }}>
                                        <div className="borderbox-label">{prop.Name}</div>
                                        <div className="borderbox-score">{`${prop.MatchConfidence}%`}</div>
                                    </div>
                                )
                        })
                    }
                    <div className="card-layer" style={{ display: imageState.processed ? 'none' : 'flex' }}>
                        <div className="spinner" hidden={imageState.icon}>
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                        <p>{imageState.message}</p>
                    </div>
                    <img id="photo" src={crtImage} alt="" />
                </div>
            </div>
        </div>
    );
}

export default V1;



const CardList = (props) => {


    const [cardData, setCardData] = useState({ key: '', url: '' });
    const [imageState, setImageState] = useState(false)

    useEffect(() => {
        getAssetURL(props.bucketKey);
        document.getElementById("img-" + props.bucketKey).addEventListener('load', function () { setImageState(true) }, false);
    }, [])

    const getAssetURL = async (bucketKey) => {
        var params = {
            Bucket: S3_BUCKET,
            Key: bucketKey
        };
        var url = await s3.getSignedUrl('getObject', params);
        setCardData({ key: bucketKey, url: url });
    }

    return (
        <div className="cardList" onClick={() => props.action(cardData)}>
            <div className="cardList-Layer" hidden={imageState}>
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
            <img id={`img-${props.bucketKey}`} src={cardData.url} alt="" />
        </div>
    );
} 

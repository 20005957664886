import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import logoIcon from './../../assets/images/cycloid_logo.svg'
import bulletIcon from './../../assets/images/cycloid-bullets.png';

const Main = ({
  children,
  classes
}) => {


  return (
        <div className={classes.root}>
            <div className={classes.header}>
              <img src={logoIcon} alt="" className={classes.logoIcon}/>
              <h1>Recognition</h1>
              <img src={bulletIcon} alt="" className="bullet-icon" />
            </div>
            <main>
              {children}
            </main>
            <div className={classes.footerBox}>
              &copy;
                {(new Date().getFullYear())}&nbsp;All rights reserved<br />
                Powered by&nbsp;
                < a href="https://www.cycloid.pt/index.html" target="_blank" rel="noreferrer" >&nbsp;CYCLOID Technology and Consulting, Lda.</a >&nbsp;
            </div>
        </div>
  )
}

Main.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.any
}

export default compose(
  withStyles(styles)
)(Main)




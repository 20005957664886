import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Main from './layouts/Main';
import Routes from './routes';



function App() {
  return (
 
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
              <Main>
                <Routes />
              </Main>
          </BrowserRouter>
        </Suspense>
  );
}

export default App;

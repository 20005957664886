const classes = theme => ({
    root: {
        padding: '0 20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    main: {
        overflowY: 'auto',
        flex: '1 1 auto',
        backgroundColor: '#eee',
    },
    mainPadding: {
        padding: '20px'
    },
    header: {
        zIndex: 2,
        display: 'flex',
        width: '100%',
        margin: '10px 0 36px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& h1': {
            marginLeft: 'auto',
            fontWeight: 800
        },
        '& img': {
            objectFit: 'contain'
        },
        [theme.breakpoints.down('sm')]: {
            '& .bullet-icon': {
                display: 'none',
            },
            '& h1': {
                marginLeft: 0,
            },
        }
    },
    logoIcon: {
        height: '87px',
        width: '294px'
    },
    footerBox: {
        zIndex: 2,
        marginTop: 'auto',
        color: '#000',
        fontSize: '11px',
        marginLeft: 'auto',
        paddingTop: '30px',
        paddingBottom: '20px',
        paddingRight: '20px',
        textAlign: 'right',
        '& p': {
            fontStyle: 'italic',
            color: '#000',
        },
        '& a': {
            color: '#B0191A',
            fontWeight: 600
        }
    }
})

export default classes;
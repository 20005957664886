
const classes = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerType: {
        width: '100%'
    },
    checkbox: {
        color: 'rgba(165, 42, 42, 1)',
        '&.Mui-checked': {
            color: 'rgba(165, 42, 42, 1)'
        }
    },
    checkboxDeafult: {
        color: 'black',
        '&.Mui-checked': {
            color: 'black'
        }
    },
    boxResultLabels: {
        maxHeight: '400px',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    image: {
        width: '100%',
        backgroundColor: '#FFF'
    },
    containerUpload: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center'
    },
    imagesBox: {
        margin: '30px 0',
        maxHeight: '500px',
        overflowY: 'auto',
        [theme.breakpoints.down(1600)]: {
            maxHeight: '250px',
        }
    },
    containerResult: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    resultLabels: {
        paddingTop: '60px',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '0 20px',
        justifyContent: 'center'
    },
    label: {
        backgroundColor: 'rgba(165, 42, 42, 1)',
        borderRadius: '20px',
        minWidth: '120px',
        padding: '10px 15px',
        color: 'white',
        margin: '10px',
        fontSize: '12px',
        textAlign: 'center'
    }
})
export default classes;
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { withStyles } from '@material-ui/core';


const ImageResulText = ({
    classes,
    data,
    sizeImage
}) => {


    if (!data) {
        return null
    }

    return (
    <>
            {
                data.map((item, index) => {
                    const geometry = item.Geometry
                    return (
                        <div key={`${index}`} className={classes.boxText} style={{
                            left: geometry.BoundingBox.Left * sizeImage.width + 'px',
                            top: geometry.BoundingBox.Top * sizeImage.height + 'px',
                            width: geometry.BoundingBox.Width * sizeImage.width + 'px',
                            height: geometry.BoundingBox.Height * sizeImage.height + 'px'
                        }}>
                        </div>
                    )
                })
            }
            </>
    )
}

ImageResulText.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(ImageResulText)

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { withStyles } from '@material-ui/core';


const ImageResultFaces = ({
    classes,
    data,
    sizeImage
}) => {


    if (!data) {
        return null
    }

    return (
    <>
            {
                data.map((item, index) => {
                    return (
                        <div key={`${index}`} className="borderbox" style={{
                            left: item.BoundingBox.Left * sizeImage.width + 'px',
                            top: item.BoundingBox.Top * sizeImage.height + 'px',
                            width: item.BoundingBox.Width * sizeImage.width + 'px',
                            height: item.BoundingBox.Height * sizeImage.height + 'px'
                        }}>
                            <div className="borderbox-score" style={{bottom: '-26px'}}>{`${item.Confidence.toFixed(2)}%`}</div>
                        </div>
                    )
                })
            }
            </>
    )
}

ImageResultFaces.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(ImageResultFaces)

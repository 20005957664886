import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import V1 from '../screens/V1';
import V2 from '../screens/V2';


const Routes = () => {
    return (
        <Switch>
            <Route
                path="/v1"
                exact
                component={V1}
            />
            <Route
                path="/v2"
                exact
                component={V2}
            />
              <Redirect 
               from="/" 
               to="/v1" 
               exact
            />
            <Redirect
                to="/"
            />
        </Switch>
    )
}
export default Routes;
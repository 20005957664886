import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { withStyles } from '@material-ui/core';


const ImageResultEquipment = ({
    classes,
    data,
    sizeImage
}) => {




    if (!data) {
        return null
    }

    return (
        <div>
            {
                data.map((item, key) => {
                    const bodyParts = item.BodyParts
                    return bodyParts.map((bodypart, index) => {
                        const equipmentDetections = bodypart.EquipmentDetections
                        return equipmentDetections.map((equipment, index) => {
                            return (
                                <div key={`${index}`} className={classes.boxEquipment} style={{
                                    left: equipment.BoundingBox.Left * sizeImage.width + 'px',
                                    top: equipment.BoundingBox.Top * sizeImage.height + 'px',
                                    width: equipment.BoundingBox.Width * sizeImage.width + 'px',
                                    height: equipment.BoundingBox.Height * sizeImage.height + 'px'
                                }}>
                                    <div className="borderbox-label">{equipment.Type}</div>
                                    <div className="borderbox-score">{`${equipment.Confidence.toFixed(2)}%`}</div>
                                </div>
                            )
                        })
                    })
                })
            }
        </div>
    )
}

ImageResultEquipment.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(ImageResultEquipment)

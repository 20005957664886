import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { withStyles } from '@material-ui/core';


const ImageResultObjectsAndScenes = ({
    classes,
    data,
    sizeImage
}) => {

    if (!data) {
        return null
    }

    return (
        <div>
            {
                data.map((item, key) => {
                        const instances = item.Instances
                        const name = item.Name
                        const confidence = item.Confidence
                        return instances.map( (instance, index) => {
                            return (
                                <div key={`${index}`} className="borderbox" style={{
                                    left: instance.BoundingBox.Left * sizeImage.width + 'px',
                                    top: instance.BoundingBox.Top * sizeImage.height + 'px',
                                    width: instance.BoundingBox.Width * sizeImage.width + 'px',
                                    height: instance.BoundingBox.Height * sizeImage.height + 'px'
                                }}>
                                    <div className="borderbox-label">{name}</div>
                                    <div className="borderbox-score">{`${confidence.toFixed(2)}%`}</div>
                                </div>
                            )
                        })
                })
            }
        </div>
    )
}

ImageResultObjectsAndScenes.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(ImageResultObjectsAndScenes)

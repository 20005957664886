import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import clsx from 'clsx';
import ReactJson from 'react-json-view'
import styles from './styles';
import {
    withStyles,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Checkbox,
    TextField,
    FormControl,
    FormLabel
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './../../App.css';
import ICON from './../../image.svg';
import ICON2 from './../../upload.svg';

import ImageResultObjectsAndScenes from './../../components/ImageResultObjectsAndScenes'
import ImageResultCelebrities from './../../components/ImageResultCelebrities';
import ImageResultFaces from './../../components/ImageResultFaces';
import ImageResultText from './../../components/ImageResulText';
import ImageResultEquipment from './../../components/ImageResultEquipment';




var AWS = require('aws-sdk');
AWS.config = new AWS.Config({ region: 'eu-west-1' });
AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: 'eu-west-1:5167c627-9fe6-407c-8821-c9136edfb834' });

const rekognition = new AWS.Rekognition();
const s3 = new AWS.S3();
const S3_BUCKET = "react-rekognition-celeb";






const V2 = ({
    classes
}) => {
    const [crtImage, setCrtImage] = useState();
    const [sizeImage, setSizeImage] = useState({ width: 450, height: 600 });
    const [imageState, setImageState] = useState({ processed: true, icon: false, message: 'Analisar Foto', init: false })
    const [isUploading, setIsUploading] = useState(false);
    const [assetList, setAssetList] = useState([]);
    const [resultList, setResultList] = useState([]);
    const [resultSelected, setResultSeleted] = useState('celebrity')


    const [detectingOptions, setDetectingOptions] = useState({
        celebrity: true,
        objectsAndScenes: false,
        faces: false,
        text: false,
        ppe: false
    }); 

    
    const [celebrityResult, setCelebrityResult] = useState(null);
    const [objectsAndScenesResult, setObjectsAndScenesResult] = useState(null);
    const [facesResult, setFacesResult] = useState(null);
    const [textResult, setTextResult] = useState(null);
    const [protectiveEquipmentPersonResult, setProtectiveEquipmentPersonResult] = useState([]);



        
        
    const handleChange = (event) => {
        const dtOptns = { ...detectingOptions, [event.target.name]: event.target.checked }
        setDetectingOptions(dtOptns);
        var keys = Object.keys(dtOptns);
        var resulst = keys.filter((key) => dtOptns[key]);
        var filtered = keys.filter((key) =>
            isWithResult(key, resulst) ? dtOptns[key] : null
        );
        setResultList(filtered)
        if (filtered) {
            setResultSeleted(filtered[0])
        } 
    };

    

    const isWithResult = (key, results) => {
        return results.includes(key)
    }


    useEffect(() => {
        loadAssets();
    }, []);

    const uploadImage = () => {
        document.getElementById("upload-input").click();
    }

    const uploadImageOnChange = (data) => {
        //console.log(data.target.files);

        var reader = new FileReader();
        reader.readAsArrayBuffer(data.target.files[0]);

        reader.onload = function () {
            let result = reader.result;
            let array = new Uint8Array(result);
            uploadAsset(array, data.target.files[0].name);
        }
    }

    const setAnalyzeImage = async (image) => {
        setCrtImage(image);
        return await setTimeout(() => {
            var imageSize = document.getElementById('photo');
            setSizeImage({ width: imageSize.clientWidth, height: imageSize.clientHeight });
        }, 1000);
    }

    const analyzePicture = async (url, key) => {
       setCelebrityResult(null)
        setObjectsAndScenesResult(null)
        setFacesResult(null)
        setTextResult(null)
        setProtectiveEquipmentPersonResult(null)
        let resulst = []
        setResultList([])
        setImageState({ processed: false, icon: false, message: 'Analisar Foto', init: true });
        await setAnalyzeImage(url);
      
        if (detectingOptions.celebrity) {
            const resp = await analyzeCelebrity(url, key);
            setCelebrityResult(resp)
            resulst.push('celebrity')
        }

        if (detectingOptions.objectsAndScenes) {
            const resp = await analyzeLabels(url, key);
            setObjectsAndScenesResult(resp)
            resulst.push('objectsAndScenes')
        }

        if (detectingOptions.text) {
            const resp = await detectText(url, key)
            setTextResult(resp)
            resulst.push('text')

        }

        if (detectingOptions.faces) {
            const resp = await detectFaces(url, key)
            setFacesResult(resp)
            resulst.push('faces')
        }

        if (detectingOptions.ppe) {
            const resp = await detectPersonalProtectiveEquipment(url, key)
            setProtectiveEquipmentPersonResult(resp)
            resulst.push('ppe')
        }

        setTimeout(() => {
            const dtOptns = { ...detectingOptions }
            var keys = Object.keys(dtOptns);
            var filtered = keys.filter((key) =>
                isWithResult(key, resulst) ? dtOptns[key] : null
            );
            setResultList(filtered)
            if (filtered) {
                setResultSeleted(filtered[0])
            }

            setImageState({ processed: true, icon: true, message: '', init: true });
        }, 10);

       

    }

    const analyzeCelebrity = async (url, key) => new Promise((resolve, reject) => { 
        var params = {
            Image: {
                S3Object: {
                    Bucket: S3_BUCKET,
                    Name: key,
                }
            }
        }
        rekognition.recognizeCelebrities(params, (err, response) => {
            if (err) {
                resolve([])
            } else {
                resolve(response.CelebrityFaces)
            }
        })
    })
    
    
   

    // Detecting objects and scenes:
    const analyzeLabels = async (url, key) => new Promise((resolve, reject) => {
        var params = {
            Image: {
                S3Object: {
                    Bucket: S3_BUCKET,
                    Name: key,
                }
            }
        };
        rekognition.detectLabels(params, (err, response) => {
            if (err) {
                resolve([])

            } else {
                resolve(response.Labels)

            }
        })
    })

    // Detects text in the input image and converts it into machine-readable text.
    const detectText = async (url, key) => new Promise((resolve, reject) => {
        var params = {
            Image: {
                S3Object: {
                    Bucket: S3_BUCKET,
                    Name: key,
                }
            }
        };
        rekognition.detectText(params, (err, response) => {
            if (err) {
                resolve([])
            } else {
                resolve(response.TextDetections)
            }
        })
    })


    // Detects faces within an image that is provided as input.
    // DetectFaces detects the 100 largest faces in the image.
    // For each face detected, the operation returns face details.
    const detectFaces = async (url, key) => new Promise((resolve, reject) => {
        var params = {
            Image: {
                S3Object: {
                    Bucket: S3_BUCKET,
                    Name: key,
                }
            }
        };
        rekognition.detectFaces(params, (err, response) => {
            if (err) {
                resolve([])
            } else {
                resolve(response.FaceDetails)
            }
        })
    })

    // Detects Personal Protective Equipment(PPE) worn by people detected in an image.Amazon Rekognition can detect the following types of PPE.
    // You pass the input image as base64 - encoded image bytes or as a reference to an image in an Amazon S3 bucket.
    // The image must be either a PNG or JPG formatted file.DetectProtectiveEquipment detects PPE worn by up to 15 persons detected in an image.
    // For each person detected in the image the API returns an array of body parts(face, head, left - hand, right - hand).
    // For each body part, an array of detected items of PPE is returned, including an indicator of whether or not the PPE covers the body part.
    // The API returns the confidence it has in each detection(person, PPE, body part and body part coverage).
    // It also returns a bounding box(BoundingBox) for each detected person and each detected item of PPE.
    // You can optionally request a summary of detected PPE items with the SummarizationAttributes input parameter.
    // The summary provides the following information.The persons detected as wearing all of the types of PPE that you specify.
    // The persons detected as not wearing all of the types PPE that you specify.
    // The persons detected where PPE adornment could not be determined.
    // This is a stateless API operation.That is, the operation does not persist any data.
    // This operation requires permissions to perform the rekognition: DetectProtectiveEquipment action. 
    const detectPersonalProtectiveEquipment = async (url, key) => new Promise((resolve, reject) => {
        var params = {
            Image: {
                S3Object: {
                    Bucket: S3_BUCKET,
                    Name: key,
                }
            }
        };
        rekognition.detectProtectiveEquipment(params, (err, response) => {
            if (err) {
                resolve([])
            } else {
                resolve(response.Persons)
            }
        })
    })

    const loadAssets = () => {
        var params = {
            Bucket: S3_BUCKET,
        };
        s3.listObjectsV2(params).promise().then(data => {
            setAssetList([]);
            setAssetList(data.Contents);
        }).catch(error => {
            //console.log(error);
        });
    }

    const uploadAsset = (bytes, name) => {
        setIsUploading(true);
        var params = {
            Body: bytes,
            Bucket: S3_BUCKET,
            Key: name
        };
        s3.putObject(params).promise().then(data => {
            loadAssets();
            setIsUploading(false);

        }).catch(error => {
            setIsUploading(false);

        })
    }

    const renderJson = (list) => {
        if (!list) return null
        const render = list.map(data => (
            <ReactJson src={data} displayDataTypes={false} />
        ))
        return render;

    }




    const renderResultImage = () => {
        switch (resultSelected) {
            case 'celebrity':
                return <ImageResultCelebrities data={celebrityResult} sizeImage={sizeImage} />
            case 'objectsAndScenes':
                return <ImageResultObjectsAndScenes data={objectsAndScenesResult} sizeImage={sizeImage} />
            case 'faces':
                return <ImageResultFaces data={facesResult} sizeImage={sizeImage} />
            case 'text':
                return <ImageResultText data={textResult} sizeImage={sizeImage} />
            case 'ppe':
                return <ImageResultEquipment data={protectiveEquipmentPersonResult} sizeImage={sizeImage} />
            default:  
                break;
        }
    }

    const renderResultLabels = () => {
        switch (resultSelected) {
            case 'celebrity':
                if (!celebrityResult) return null
                 return celebrityResult.map((item, index) => {
                    const name = item.Name
                    const confidence = item.MatchConfidence
                    return (
                        <div className={classes.label}>{name} [{`${confidence.toFixed(2)}%`}]</div>
                    )
                })
            case 'objectsAndScenes':
                if (!objectsAndScenesResult) return null
                return objectsAndScenesResult.map((item, index) => {
                    const instances = item.Instances
                    const name = item.Name
                    const confidence = item.Confidence
                    return (
                        <div className={classes.label}>{name} [{`${confidence.toFixed(2)}%`}]</div>
                    )
                })
            case 'faces':
                return null
            case 'text':
                if (!textResult) return null
                return textResult.map((item, index) => {
                    const name = item.DetectedText
                    const confidence = item.Confidence
                    return (
                        <div className={classes.label}>{name} [{`${confidence.toFixed(2)}%`}]</div>
                    )
                })
            case 'ppe':
                if (!protectiveEquipmentPersonResult) return null
                return protectiveEquipmentPersonResult.map((item, key) => {
                    const bodyParts = item.BodyParts
                    return bodyParts.map((bodypart, index) => {
                        const equipmentDetections = bodypart.EquipmentDetections
                        return equipmentDetections.map((equipment, index) => {
                            return (
                                 <div className={classes.label}>{equipment.Type} [{`${equipment.Confidence.toFixed(2)}%`}]</div>
                            )
                        })
                    })
                })
            default:
                break;
        }
    }

    return (
        <div classsName={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classes.containerUpload}>
                        <div className={classes.buttonBox}>
                            <div id="upload-btn" className="upload-button" onClick={() => { if (!isUploading) uploadImage() }} disabled>
                                <div className="button-layer" style={{ display: isUploading ? 'flex' : 'none' }}>
                                    <div className="spinner">
                                        <div className="bounce1"></div>
                                        <div className="bounce2"></div>
                                        <div className="bounce3"></div>
                                    </div>
                                </div>
                                <img src={ICON2} alt="" />
                                Upload Foto
                            </div>
                            <input id="upload-input" onChange={uploadImageOnChange} accept="image/x-png,image/jpeg" type="file" hidden />
                        </div>
                        <div className={classes.imagesBox}>
                            <div className="container-row-wrap" style={{ height: '100%' }}>
                                {
                                    assetList.map((prop, key) => {
                                        if (assetList.length > 0)
                                            return (
                                                <CardList
                                                    key={key}
                                                    bucketKey={prop.Key}
                                                    action={(data) => analyzePicture(data.url, data.key)} />)
                                    })
                                }
                            </div>
                        </div>
                        <FormLabel style={{ margin: '20px' }}>Seleciona as opções que pretende analizar</FormLabel>
                        <div className={classes.containerType}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-detecting-celebrity"
                                    id="panel-detecting-celebrity-header"
                                >
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={detectingOptions.celebrity}
                                                onChange={handleChange}
                                                name="celebrity" />}
                                        label="Celebridades"
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.boxResultLabels}>
                                        {
                                            renderJson(celebrityResult)
                                        }
                                    </div>

                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-detecting-objects-and-scenes"
                                    id="panel-detecting-objects-and-scenes-header"
                                >
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={detectingOptions.objectsAndScenes}
                                                onChange={handleChange}
                                                name="objectsAndScenes" />}
                                        label="Objetos e cenas"
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.boxResultLabels}>
                                        {
                                            renderJson(objectsAndScenesResult)

                                        }
                                    </div>

                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-detecting-faces"
                                    id="panel-detecting-faces-header"
                                >
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={detectingOptions.faces}
                                                onChange={handleChange}
                                                name="faces" />}
                                        label="Rostos"
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.boxResultLabels}>
                                        {
                                            renderJson(facesResult)

                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-detecting-text"
                                    id="panel-detecting-text-header"
                                >
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={detectingOptions.text}
                                                onChange={handleChange}
                                                name="text" />}
                                        label="Textos"
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.boxResultLabels}>
                                        {
                                            renderJson(textResult)

                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel-detecting-ppe"
                                    id="panel-detecting-text-ppe"
                                >
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={detectingOptions.ppe}
                                                onChange={handleChange}
                                                name="ppe" />}
                                        label="Equipamentos de proteção pessoal"
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.boxResultLabels}>
                                        {
                                            renderJson(protectiveEquipmentPersonResult)
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div className={classes.containerResult}>
                        <div className={classes.boxResultSelect}>
                            <FormControl fullWidth style={{ marginBottom: '20px' }}>
                                <FormLabel>Seleciona o resultado que pretende ver</FormLabel>
                                <TextField
                                    id="result"
                                    select
                                    margin="normal"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    value={resultSelected}
                                    onChange={(event) => setResultSeleted(event.target.value)}
                                >
                                    {resultList.map((item) => (
                                        <option key={item} value={item}>
                                            {getLabel(item)}
                                        </option>
                                    ))}
                                </TextField>
                            </FormControl>
                        </div>
                        <div className="card-placeholder" style={{ display: imageState.init ? 'none' : 'flex' }}>
                            <img src={ICON} alt="" />
                            <p>Escolha uma Foto</p>
                            <p>ou</p>
                            <p>Faça Upload</p>
                        </div>
                        <div className="card" style={{ maxWidth: '100%', boxShadow: 'none', overflow: 'unset', height: 'auto', borderRadius: '0px'}}>
                                {renderResultImage()}
                            <div className="card-layer" style={{ display: imageState.processed ? 'none' : 'flex' }}>
                                <div className="spinner" hidden={imageState.icon}>
                                    <div className="bounce1"></div>
                                    <div className="bounce2"></div>
                                    <div className="bounce3"></div>
                                </div>
                                <p>{resultSelected === 'celebrity' ? imageState.message : ''}</p>
                            </div>
                            {crtImage ? <img id="photo" src={crtImage} alt="" className={classes.image} /> : null }
                           
                        </div>
                        <div className={classes.resultLabels}>
                            {renderResultLabels()}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>

        
       
    );
}

V2.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(V2)




const CardList = (props) => {


    const [cardData, setCardData] = useState({ key: '', url: '' });
    const [imageState, setImageState] = useState(false)

    useEffect(() => {
        getAssetURL(props.bucketKey);
        document.getElementById("img-" + props.bucketKey).addEventListener('load', function () { setImageState(true) }, false);
    }, [])

    const getAssetURL = async (bucketKey) => {
        var params = {
            Bucket: S3_BUCKET,
            Key: bucketKey
        };
        var url = await s3.getSignedUrl('getObject', params);
        setCardData({ key: bucketKey, url: url });
    }

    return (
        <div className="cardList" onClick={() => props.action(cardData)}>
            <div className="cardList-Layer" hidden={imageState}>
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
            <img id={`img-${props.bucketKey}`} src={cardData.url} alt="" />
        </div>
    );
} 


const getLabel = value => {
    switch (value) {
        case 'celebrity':
            return 'Celebridades'
        case 'objectsAndScenes':
            return 'Objectos e Cenas'
        case 'faces':
            return 'Rostos'
        case 'text':
            return 'Textos'
        case 'ppe':
            return 'Equipamentos de proteção pessoal'
        default: break
    }
}
